<template>
  <div>
    <analytics-company-percent-chart
      :data="series"
      :option="temp"
      :legend="dataList"
    />

    <div class="page-container pb-0 mb-2">
      <filter-component
        @filterDistrict="handleFilter($event)"
      />
    </div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :arrayExcel="[]"
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header pr-1"
        @clickExportExcel="dowloadFile"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
    </div>
  </div>

</template>

<script>
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import FilterComponent from './component/FilterComponent.vue'
import store from '../../store'
import ChartOption from '../configChart'
import AnalyticsCompanyPercentChart from './component/AnalyticsCompanyPercentChart.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constansts/ConstanstApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    FilterComponent,
    AnalyticsCompanyPercentChart,
  },
  data() {
    return {
      ChartOption,
      temp: {
        chart: {
          type: 'donut',
          fontFamily: 'Montserrat',
        },
      },
      series: [],
      dataList: [],
      dataChart: [],
      columns: [
        {
          label: 'ĐƠN VỊ SỬ DỤNG LAO ĐỘNG',
          field: 'name',
        },
        {
          label: 'SỐ LƯỢNG LAO ĐỘNG',
          field: 'value',

        },
      ],
      // ==phân trang
      urlQuery: {
        bType: null,
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      currentPage: 10,
      nameChart: [],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    async dowloadFile() {
      const name = 'DonViSuDungLaoDong.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    // Lay data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_STATICAL_BUSSINESS, {
        params: urlQuery,
      })
      this.dataList = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.series = this.dataList.map(e => e.value)
      this.ChartOption.labels = this.dataList.map(e => e.name)
      this.nameChart = this.dataList.map(e => e.name)
      this.temp = this.ChartOption
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },

    // xử lý filter
    handleFilter(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bType = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bType = null
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style>

</style>
