<template>
  <div>

    <b-card
      class="card-tiny-line-stats pb-5 d-flex"
      body-class="pb-50"
    >
      <h4 class="mb-1">
        Theo loại hình doanh nghiệp
      </h4>
      <!-- chart -->
      <div class="chart-percent-donut pt-2">

        <vue-apex-charts
          class="char-percent"
          :options="option"
          :series="data"
          height="350px"
          width="350px"
        />
        <b-row
          class="legend-chart-percent-donut"
        >
          <b-row
            v-for="(item, index) in legend"
            :key="index"
            class="item-legend-chart d-flex align-items-start"
            cols="4"
          >
            <b-col
              cols="2"
            >
              <div :class="['item-color-rating'+ index , 'item-color-rating text-truncate']"></div>
            </b-col>
            <b-col
              class="val-items-legend"
              cols="8"
            >
              <h5 class="text-legnd-chart">
                {{ item.name }}
              </h5>
            </b-col>
            <b-col
              cols="2"
              class="val-items-legend"
            >
              <h5
                class="val-items-legend"
              >{{ item.value }}</h5>
            </b-col>
          </b-row>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
    BImg,
    BRow,
    BCol,
  },
  props: {
    option: {
      type: Object,
      default: _ => {},
    },
    data: {
      type: Array,
      default: _ => [],
    },

    legend: {
      type: Array,
      default: _ => [],
    },
  },
}
</script>

<style lang="scss">
.item-color-rating {
    display: block;
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
  }
  .item-color-rating0 {
    border: 2px solid #4F5D70;
  }
  .item-color-rating1 {
    border: 2px solid #28DAC6;
  }
  .item-color-rating2 {
    border: 2px solid #FFE802;
  }
  .item-color-rating3 {
    border: 2px solid #227FF4;
  }
  .item-color-rating4 {
    border: 2px solid #EA5455;
  }

  .chart-percent-donut{
    display: flex;
    align-items: center;
    margin-left: 20%;
  }

  .legend-chart-percent-donut{
    display: flex;
    margin-left: 10%;
    width: 60%;
  }
  .item-legend-chart{
    display: flex;
    width: inherit;
    padding: 8px 0;
  }

  .val-items-legend{
    font-size: 14px;
    margin-left: -6%;
  }
  .list-items-val{
    margin-left: 30px;
    width: 50px;
  }
@media(max-width: 1800px) {
 .legend-chart-percent-donut{
    margin-left: 5%;
 }
}
@media(min-width: 1024px) and (max-width: 1364px) {
  .chart-percent-donut{
    display: flex;
    align-items: center;
    margin-left: 5%;
  }

}
@media(min-width: 700px) and (max-width: 1024px) {
  .chart-percent-donut{
    margin-left: 0;
  }
  .legend-chart-percent-donut{
    margin-left: 5px;
    width: 100%;
  }
 .list-items-val{
    margin-left: 5px;
    width: 30px;
  }
}
@media (min-width: 550px)and(max-width: 700px) {
 .chart-percent-donut{
    display: block;
    margin-left: 20%;
  }
  .legend-chart-percent-donut{
    display: flex;
    width: 100%;
    margin-left: 50px;
 }
 .list-items-val{
    width: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 550px){

  .card-body{
    width: 100%;
  }
  .chart-percent-donut{
    display: block;
    margin-left: 10%;
  }
  .legend-chart-percent-donut{
    display: flex;
    width: 100%;
    margin-left: 50px;
 }
 .list-items-val{
    width: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 430px){
.chart-percent-donut{
    display: block;
    margin-left: 0;
  }
  .legend-chart-percent-donut{
    display: flex;
    width: 100%;
    margin-left: 20px;
 }
 .list-items-val{
    width: 30px;
    margin-left: 20px;
  }
  .char-percent{
    margin-left: -20px;
  }
}
</style>
