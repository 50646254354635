export default {
  colors: ['#4F5D70', '#28DAC6', '#FFE802', '#227FF4', '#EA5455'],
  legend: {
    show: false,
  },
  chart: {
    type: 'donut',
    fontFamily: 'Montserrat',
  },
  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true,
            label: 'Tổng',
            color: '#227FF4',
            fontFamily: 'Montserrat',
            fontSize: '22px',

          },
        },
      },

    },
  },
  labels: [],
}
